import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullableNumber',
  standalone: true
})
export class NullableNumberPipe implements PipeTransform {
  transform(value: number | null | undefined, format: string = '1.0-0', fallback: string = 'N/A'): string {
    if (value === null || value === undefined) {
      return fallback;
    }

    // Parse the format string to extract minimum and maximum fraction digits
    const [minDigits, maxDigits] = format
      .split('-')
      .map((part) => parseInt(part, 10));

    const maximumFractionDigits = isNaN(maxDigits) ? 0 : maxDigits;

    const userLocale = navigator.language || 'en-US'; 

    if(maximumFractionDigits == 0){
      return new Intl.NumberFormat(userLocale,{maximumFractionDigits:0}).format(value);
    }
    
    return new Intl.NumberFormat(userLocale, {
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  }
}
