import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeaGenerationServiceService } from '../../services/idea-generation-service.service';
import { ItopFlopClusters, ItopFlopClustersData, ItopFlopKeywords, ItopFlopKeywordsData, ItopFlopThemes, ItopFlopThemesData, ItopThemes, ITreemapQuery } from '../../../../core/models/idea-generation.model';
import { Store } from '@ngrx/store';
import { submitSearch } from '../../../../state/action';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { NullableNumberPipe } from '../../../../shared/pipes/nullable-number.pipe';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-themes',
  templateUrl: './top-themes.component.html',
  styleUrls: ['./top-themes.component.css'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule,NullableNumberPipe],
})
export class TopThemesComponent {
  @Input() market!: string;
  treemapQuery: ITreemapQuery = {
    market_cap: 'all',       // Default market cap
    countries: [],          // Default country codes
    date_end: null,               // Default date
    query: null,
    entityids: null,
    search_type: "clusters"
  };
  disableButtons$: Observable<boolean> = this.stateService.disableButtons$;

  constructor(private ideaGenService: IdeaGenerationServiceService, private store: Store, private stateService: IdeaGenStateService) {
    this.stateService.treemapQuery$.subscribe((treemapQuery) => {
      this.treemapQuery = treemapQuery;
      // console.log("top flop themes component",treemapQuery);
      if (treemapQuery.search_type == "themes") {
        this.getTopFlopThemes(treemapQuery);
      } else if (treemapQuery.search_type == "clusters") {
        this.getTopFlopClusters(treemapQuery)
      } else {
        this.getTopFlopKeywords(treemapQuery)

      }

    });
    // //subscribe to changes in disableButtons variable
    // this.stateService.disableButtons$.subscribe((value) => {
    //   this.disableButtons = value;
    //   console.log("top themes", this.disableButtons);
    // });


  }

  showSpinner: boolean = false;
  showTable: boolean = false;

  // To track which section to display
  showTopThemes: boolean = true;

  // To track the expanded row index
  expandedIndex: number | null = null;

  // Top and Flop Themes data
  top_flop_themes: ItopFlopThemes = {
    top_themes: [], flop_themes: [],
    average_relative_growth: 0,
    average_invention_count_12m: 0
  };

  // Top and Flop keywords data
  top_flop_keywords: ItopFlopKeywords = {
    top_keywords: [], flop_keywords: [],
    average_relative_growth: 0,
    average_invention_count_12m: 0
  };
  // Top and Flop clusters data
  top_flop_clusters: ItopFlopClusters = {
    top_clusters: [], flop_clusters: [],
    average_relative_growth: 0,
    average_invention_count_12m: 0
  };



  toggleThemeView(showTop: boolean): void {
    this.showTopThemes = showTop;
    // Reset expanded row index when switching views
    this.expandedIndex = null;
  }

  toggleCollapse(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }



  getTopFlopThemes(query: ITreemapQuery) {
    // console.log("getTopFlopThemes", query);
    this.stateService.incrementActiveRequests();

    this.showSpinner = true;
    this.showTable = false;
    this.ideaGenService.getTopFlopThemes(query).subscribe({
      next: (res) => {
        this.showSpinner = false;
        this.top_flop_themes = res.payload;
        this.showTable = true;
        this.stateService.decrementActiveRequests();

      },
      error: (err) => {
        alert('Failed to load top and flop themes!');
        this.stateService.decrementActiveRequests();
        this.showSpinner = false;
        this.showTable = false;
      },
    });
  }

  getTopFlopKeywords(query: ITreemapQuery) {
    this.stateService.incrementActiveRequests();

    this.showSpinner = true;
    this.showTable = false;
    this.ideaGenService.getTopFlopKeywords(query).subscribe({
      next: (res) => {
        this.showSpinner = false;
        this.top_flop_keywords = res.payload;
        this.showTable = true;
        this.stateService.decrementActiveRequests();

      },
      error: (err) => {
        alert('Failed to load top and flop themes!');
        this.showSpinner = false;
        this.showTable = false;
        this.stateService.decrementActiveRequests();

      },
    });
  }

  getTopFlopClusters(query: ITreemapQuery) {
    this.stateService.incrementActiveRequests();

    this.showSpinner = true;
    this.showTable = false;
    this.ideaGenService.getTopFlopClusters(query).subscribe({
      next: (res) => {
        this.showSpinner = false;
        this.top_flop_clusters = res.payload;
        this.showTable = true;
        this.stateService.decrementActiveRequests();

      },
      error: (err) => {
        alert('Failed to load top and flop themes!');
        this.showSpinner = false;
        this.showTable = false;
        this.stateService.decrementActiveRequests();

      },
    });
  }




  submitTheme(themeInfo: ItopFlopThemesData) {
    themeInfo.query = `tf="${themeInfo.tf}"`;
    const searchParams = { themes: [{ api_query: themeInfo.query, name: themeInfo.tf }] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }

  submitCluster(clusterInfo: ItopFlopClustersData) {
    clusterInfo.query = `th="${clusterInfo.th}"`;
    const searchParams = { themes: [{ api_query: clusterInfo.query, name: clusterInfo.th }] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }

  submitKeyword(keywordInfo: ItopFlopKeywordsData) {
    keywordInfo.query = `ctxt="${keywordInfo.ti_kw}"`;
    const searchParams = { themes: [{ api_query: keywordInfo.query, name: keywordInfo.ti_kw}] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }
}
