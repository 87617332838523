<div class="container">
  <h4 class="heading">Idea Generation</h4>
  <hr />
  <div class="row">
    <div class="col">
      <h3 class="heading">Technology Trends</h3>
      <ul class="list">
        <button (click)="handleOptionClick('topFlopThemes', 'all')"
          [ngClass]="{'list-item-active': isActive('topFlopThemes', 'all')}" class="list-item"  [disabled]="disableButtons$ | async">
          Top / Flop Technologies
        </button>
      </ul>



      <br><br>
      <ng-container>
        <h3 class="heading">Example Views</h3>
        <ul class="list">

          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'US')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'US')}" class="list-item"
            [disabled]="disableButtons$ | async">
            United States
          </button>
          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Europe')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Europe')}" class="list-item"  [disabled]="disableButtons$ | async">
            Europe
          </button>

          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'China')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'China')}" class="list-item" [disabled]="disableButtons$ | async" >
            China
          </button>
          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Large Cap')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Large Cap')}" class="list-item"  [disabled]="disableButtons$ | async">
            Large Cap
          </button> <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Small Cap')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Small Cap')}" class="list-item"  [disabled]="disableButtons$ | async">
            Small Cap
          </button>
          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Automotive')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Automotive')}" class="list-item"  [disabled]="disableButtons$ | async">
            Automotive
          </button>
          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Computer Systems')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Computer Systems')}" class="list-item"  [disabled]="disableButtons$ | async">
            Computer Systems
          </button>
          <button (click)="sendTechfieldHightlightsData('qipHighlightsTechfields', 'Magnificent7')"
            [ngClass]="{'list-item-active': isActive('qipHighlightsTechfields', 'Magnificent7')}" class="list-item"  [disabled]="disableButtons$ | async">
            Magnificent 7
          </button>

        </ul>
      </ng-container>

      <br><br>
      <ng-container *ngIf="roles!.includes('alpha') ">
        <h3 class="heading">Quant IP Clustering</h3>
        <ul class="list">
          <button (click)="handleClustersOptionClick('qipHighlights', 'Magnificent5')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'Magnificent5')}" class="list-item"  [disabled]="disableButtons$ | async">
            Magnificent 5
          </button>
          <button (click)="handleClustersOptionClick('qipHighlights', 'US')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'US')}" class="list-item"  [disabled]="disableButtons$ | async">
            United States
          </button>
          <button (click)="handleClustersOptionClick('qipHighlights', 'Europe')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'Europe')}" class="list-item"  [disabled]="disableButtons$ | async">
            Europe
          </button>
          <button (click)="handleClustersOptionClick('qipHighlights', 'BRICS')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'BRICS')}" class="list-item"  [disabled]="disableButtons$ | async">
            BRICS
          </button>
          <button (click)="handleClustersOptionClick('qipHighlights', 'China')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'China')}" class="list-item"  [disabled]="disableButtons$ | async">
            China
          </button>
      
        </ul>
      </ng-container>

    </div>
  </div>

</div>