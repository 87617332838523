import { Component, OnInit } from '@angular/core';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-idea-generation-sidebar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  activeTab: { type: string, market: string } = { type: 'themeTrends', market: 'developed' };
  private subscriptions: Subscription = new Subscription();
  roles: string | null = null;

  constructor(private stateService: IdeaGenStateService) { }
  @Output() techfieldHighlightsDataEvent = new EventEmitter<{ query: string | null, entityids: [string] | null ,name:string|null}>();

    disableButtons$: Observable<boolean> = this.stateService.disableButtons$;
  
  ngOnInit() {
    this.roles = JSON.parse(localStorage.getItem('roles')!);
    // Subscribe to the state service to get the current selected values
    this.subscriptions.add(
      this.stateService.selectedOption$.subscribe(option => {
        this.activeTab.type = option;
      })
    );

    this.subscriptions.add(
      this.stateService.selectedMarket$.subscribe(market => {
        this.activeTab.market = market;
      })
    );
    this.handleOptionClick('topFlopThemes', 'all');
  }
  private SEARCHES: { [key: string]: { themes: string[] } } = {
    'China': { themes: ['uent_country any "CN"'] },
    'Automotive': { themes: ['gics_uent any "251020 251010"'] },
    'Computer Systems': { themes: ['tf="Computer Systems"'] },

    "Magnificent7": {
      themes: ['uent any "snp:19317b848dbb3dcbfb5684c662fd25ff snp:8c1dccbfd25deffadc880995a842b0d4 snp:3262c13088a37147f222c34b1a9029a4 snp:28c0dc6ea9505063f00093375b140152 snp:ec3dd4dc9a1084b24b736a18994e0bd6 snp:7666534473231043db00bea461f55d33 snp:54408a2dfdea10949d9124ad3e7e9eaa"']
    },
    'US': { themes: ['uent_country any "US"'] },
    'Europe': { themes: ['uent_country any "FR DE UK IT ES PL RO NL BE SE PT GR CZ HU AT CH DK FI IE HR SK NO LT SI BG EE LV LU CY MT AL"'] },
    'BRICS': { themes: ['uent_country any "CN IN RU BR ZA"'] },
    'NATO': { themes: ['uent_country any "US UK DE FR IT CA ES TR NL PL BE NO GR DK PT CZ RO HU BG HR SK LT SI LV EE AL LU"'] },
    'Magnificent5': {
      themes: ['uent any "snp:3262c13088a37147f222c34b1a9029a4 snp:8c1dccbfd25deffadc880995a842b0d4 snp:7666534473231043db00bea461f55d33 snp:ec3dd4dc9a1084b24b736a18994e0bd6 snp:28c0dc6ea9505063f00093375b140152"'],
    },
    'Large Cap':{
      themes:['large_cap'],
    },
    'Small Cap':{
      themes:['small_cap'],
    },
  };

  isActive(type: string, market: string): boolean {
    return this.activeTab.type === type && this.activeTab.market === market;
  }

  handleClustersOptionClick(option: string, market: string) {
    // Set the active tab and notify the state service
    this.activeTab = { type: option, market: market };
    this.stateService.setSelectedOption(option, market);

    if (option === 'qipHighlights') {
      const themeValue = this.SEARCHES[market]?.themes;
      if (themeValue && themeValue.length > 0) {
        this.stateService.setQipHighlightsTheme(themeValue[0]);

      }
    }
  }



  handleOptionClick(option: string, market: string) {
    // Set the active tab and notify the state service
    this.activeTab = { type: option, market: market };
    this.stateService.setSelectedOption(option, market);
    this.stateService.setQuery({
      market_cap: 'all',       // Default market cap
      countries: [],          // Default country codes
      date_end: null,               // Default date
      query:null,
      entityids:null,
      search_type:"themes"
    });
  }

  sendTechfieldHightlightsData(option: string, market: string) {
    // Set the active tab and notify the state service
    this.activeTab = { type: option, market: market };
    this.stateService.setSelectedOption(option, market);

    if (option === 'qipHighlightsTechfields') {
      const themeValue = this.SEARCHES[market]?.themes;
      if (themeValue && themeValue.length > 0) {
        const query = themeValue[0];
        this.techfieldHighlightsDataEvent.emit({ query: query, entityids: null,name:market })
      }
    }
  }

  ngOnDestroy() {
    // Cleaning up subscriptions to avoid memory leaks
    this.subscriptions.unsubscribe();
  }


}
