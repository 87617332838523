
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


import { PlotlyModule } from 'angular-plotly.js';
import { IdeaGenerationServiceService } from '../../services/idea-generation-service.service';
import { Store } from '@ngrx/store';

import { ITreemapData, ITreemapQuery } from '../../../../core/models/idea-generation.model';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { submitSearch } from '../../../../state/action';


@Component({
  selector: 'app-theme-trends',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PlotlyModule],
  templateUrl: './theme-trends.component.html',
  styleUrl: './theme-trends.component.css',
})
export class ThemeTrendsComponent implements OnInit {
  constructor(private store: Store, private ideaGenService: IdeaGenerationServiceService, private stateService: IdeaGenStateService) {
    this.stateService.treemapQuery$.subscribe((treemapQuery) => {
      this.showSpinner = true;
      this.showPlot = false;
      this.showTheme = false;
      this.selectedTheme = '';
      setTimeout(() => {
        this.getTreemapData(treemapQuery);
 
      }, 2000);
    })
  }


  showSpinner: boolean = false;
  showPlot: boolean = false;
  ngOnInit(): void {
  }
  treemaptype: string = 'normal';
  onSearchTypeToggle(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.treemaptype = inputElement.checked ? 'delta' : 'normal';
    if(this.treemaptype=='delta'){
      this.plotRelativeGrowthDeltaTreemap();
    }else{
      this.plotRelativeGrowthTreemap();
    }
    
  }


  treemap_data: ITreemapData[] = [];
  getTreemapData(query: ITreemapQuery) {
    this.showSpinner = true;
    this.showPlot = false;
    this.showTheme = false;
    this.selectedTheme = '';
    this.ideaGenService.getTreemapData(query).subscribe({
      next: (res) => {
        this.showSpinner = false;
        this.treemapData = res.payload;
        this.plotRelativeGrowthTreemap();

        // if(this.treemaptype=='delta'){
        //   this.plotRelativeGrowthDeltaTreemap();
        // }else{
        //   this.plotRelativeGrowthTreemap();
        // }
      },
      error: (err) => {
        alert("Failed to load the data for treemap!");
        this.showSpinner = false;
        this.showPlot = false;
      },
    });
  }
  treemapDataValues: any;
  layout: any;
  config: any;
  treemapData:ITreemapData|undefined=undefined;

  plotRelativeGrowthDeltaTreemap() {
    if (!this.treemapData) {
      return;
    }
    this.showPlot = false;

    // const minVal = Math.min(...this.treemapData.relative_growth);
    // const maxVal = Math.max(...this.treemapData.relative_growth);
    const minVal = this.treemapData.cmin_relative_growth_delta;
    const maxVal =this.treemapData.cmax_relative_growth_delta;
    const trace = {
      type: 'treemap',
      branchvalues:'total',
      ids: this.treemapData.ids,        
      labels: this.treemapData.labels,   
      parents: this.treemapData.parents, 
      values: this.treemapData.values,   
      marker: {
        colors: this.treemapData.relative_growth, 
        colorscale: [
          [0, '#d60000'],  
          [0.5, 'white'],  
          [1, 'green']      
        ],
        cmin: -100,        
        cmax: 100,         
        colorbar: {        
          title: 'Relative Growth (%)',  
          tickvals: [-100, 0, 100],    
          ticktext: ['-100%', '0%', '100%'],  
        },
      },
      text: this.treemapData.labels.map((label, index) => {
        const relativeGrowth = this.treemapData!.relative_growth[index];
        const absoluteGrowth = this.treemapData!.values![index];
        return `Relative Growth: ${relativeGrowth}%<br>Absolute Growth: ${absoluteGrowth} Inv`;
      }),
      // textinfo: 'label+value+percent entry',  
      // maxdepth:2
    };
    const layout = {
      // title: 'Innovation Treemap',
      margin: { t: 50, l: 25, r: 25, b: 25 },
      height: 800,
      // maxdepth:1
    };

    this.treemapDataValues = {
      data: [trace],
      layout: layout
    };
    this.showSpinner = false;
    this.showPlot = true;
  }




  plotRelativeGrowthTreemap() {
    if (!this.treemapData) {
      return;
    }
    this.showPlot = false;

    // const minVal = Math.min(...this.treemapData.relative_growth);
    // const maxVal = Math.max(...this.treemapData.relative_growth);
   // const minVal = this.treemapData.cmin_relative_growth;
   // const maxVal =this.treemapData.cmax_relative_growth;
    const maxVal =100;
    const minVal = -100;


    const trace = {
      type: 'treemap',
      branchvalues: 'total',
      ids: this.treemapData.ids,
      labels: this.treemapData.labels,
      parents: this.treemapData.parents,
      values: this.treemapData.values,
      marker: {
        colors: this.treemapData.relative_growth,
        colorscale: [
          [0, '#d60000'],      
          [(0 - minVal) / (maxVal - minVal), 'white'], 
          [(25 - minVal) / (maxVal - minVal), 'green'], 
          [1, 'green'],        
        ],
        cmin: minVal,            
        cmax: maxVal,  
        colorbar: {
          title: 'Relative Growth (%)',
          tickvals: [minVal, (0), maxVal],
          ticktext: [`${minVal}%`, `${(0).toFixed(0)}%`, '>100%'],
        },
      },
      text: this.treemapData.labels.map((label, index) => {
        const relativeGrowth = this.treemapData!.relative_growth[index];
        const absoluteGrowth = this.treemapData!.values![index];
        return `Relative Growth: ${relativeGrowth}%<br>Absolute Growth: ${absoluteGrowth} Inv`;
      }),
      // textinfo: 'label+value+percent entry',  
      // maxdepth:2
    };
    const layout = {
      title: 'Innovation Treemap',
      margin: { t: 50, l: 25, r: 25, b: 25 },
      height: 800,
      // maxdepth:1
    };

    this.treemapDataValues = {
      data: [trace],
      layout: layout
    };
    this.showSpinner = false;
    this.showPlot = true;
  } 
  selectedThemeQuery: string = ' ';
  selectedTheme: string = '';
  showTheme: Boolean = false;
  handleClick(event: any) {
    const clickedData = event.points[0];
    this.selectedTheme = clickedData.label;
    this.showTheme = true;
    const clickedId = clickedData.id;
    const data = this.treemapData;
    const dataIndex = data!.ids.indexOf(clickedId);
    this.selectedThemeQuery = `tf="${this.selectedTheme}"`

  }

  submitTheme() {
    const searchParams = { 'themes': [{ 'api_query': this.selectedThemeQuery, 'name': this.selectedTheme }] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }
}
